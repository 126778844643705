<template>
  <v-container>
    <v-stepper v-model="e1" vertical>
      <v-stepper-step :complete="e1 > 1" step="1">
        Identificación del colaborador
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-card>
          <!-- campo de ingreso para el rut -->
          <v-col cols="12" md="4">
            <v-text-field
              v-mask="['X.XXX.XXX-X', 'XX.XXX.XXX-X']"
              v-model="rut"
              required
              prepend-inner-icon="mdi-card-account-details-outline"
              label="Ingrese rut"
              append-icon="mdi-qrcode-scan"
              maxlength="12"
              placeholder="12.345.678-9"
              @click:append="readqr()"
              @keydown.enter="getName()"
            >
            </v-text-field>
            <!-- boton de verificacion del rut, si es válido mostrará el nombre en el cuadro siguiente, si no, lanzará la alerta -->
            <v-btn color="primary" @click="getName()">
              Verificar rut
            </v-btn>
          </v-col>
          <v-col cols="12" md="8">
            <!-- campo nombre, solo se muestra si el rut es válido -->
            <v-text-field
              v-model="nombre"
              readonly
              label="Nombre colaborador"
              prepend-inner-icon="mdi-account-settings-outline"
            >
            </v-text-field>
          </v-col>
          <v-card-actions>
            <!-- boton para seguir al paso siguiente -->
            <v-btn color="primary" :disabled="tostep2" @click="e1 = 2">
              Siguiente
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-step :complete="e1 > 2" step="2">
        Tipo de movimiento
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-card>
          <v-col cols="3">
            <v-radio-group v-model="mov">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-radio label="Nueva entrega" value="new" v-bind="attrs" v-on="on" />
                </template>
                <span>Entrega de botas a nuevos colaboradores</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-radio label="Renovacion" value="renew" v-bind="attrs" v-on="on" />
                </template>
                <span>Renovacion de botas por tiempo de uso</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-radio label="Reposicion" value="replace" v-bind="attrs" v-on="on" />
                </template>
                <span>Reposicion por daño o pérdida</span>
              </v-tooltip>
            </v-radio-group>
          </v-col>
          <v-textarea
            v-model="repcomment"
            background-color="blue lighten-5"
            auto-grow
            rows="1"
            prepend-inner-icon="mdi-comment"
            label="Motivo de reposición"
            required
            v-if="mov == 'replace'"
          />
          <v-card-actions>
            <!-- boton volver atras -->
            <v-btn color="primary" @click="e1 = 1">
              Atrás
            </v-btn>
            <v-spacer></v-spacer>
            <!-- boton para seguir al paso siguiente -->
            <v-btn color="primary" :disabled="!tostep3" @click="(e1 = 3), getTalla()">
              Siguiente
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-step :complete="e1 > 3" step="3">
        Implementos a entregar
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-card>
          <v-col>
            <v-row>
              <v-checkbox v-model="bota" label="Botas" />
              <v-spacer />
              <v-switch v-if="show_switch" v-model="n_talla" label="Cambiar talla" />
            </v-row>
            <v-text-field v-model="talla" type="number" :disabled="!bota" :readonly="edit_talla" label="Talla botas" />
            <v-checkbox v-model="fono" label="Protector auditivo" />
          </v-col>
          <v-card-actions>
            <!-- boton volver atras -->
            <v-btn color="primary" @click="e1 = 2">
              Atrás
            </v-btn>
            <v-spacer></v-spacer>
            <!-- boton para seguir al paso siguiente -->
            <v-btn color="primary" :disabled="!tostep4" @click="e1 = 4">
              Siguiente
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-step :complete="e1 > 4" step="4">
        Verificar movimiento
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-card>
          <span
            >Datos de entrega
            <br />
            <br />Se entregará al colaborador {{ nombre }}, RUN: {{ rut }} un
            <span v-if="bota">par de botas talla: {{ talla }} </span>
            <span v-if="bota && fono">y un </span>
            <span v-if="fono">protector auditivo</span>
            .
            <br />
            <br />
            <v-checkbox v-model="bodega" label="Notificar entrega a Bodega" />
            <v-checkbox v-model="jefe" label="Notificar entrega a jefatura de área" />
          </span>
          <v-card-actions>
            <!-- boton volver atras -->
            <v-btn color="primary" @click="e1 = 3">
              Atrás
            </v-btn>
            <v-spacer></v-spacer>
            <!-- boton para seguir al paso siguiente -->
            <v-btn color="primary" @click="(e1 = 5), regEntrega()">
              Siguiente
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-step step="5">
        Finalizar
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-card>
          El Movimiento ha sido registrado y notificado segun lo seleccionado.
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- boton para seguir al paso siguiente -->
            <v-btn color="primary" @click="(e1 = 1), rstform()">
              Finalizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
      <!-- dialogo visor QR -->
    </v-stepper>
    <v-dialog max-width="60%" v-model="qrscan">
      <qrcode-stream v-if="qrscan" @decode="qr_reader" />
    </v-dialog>
    <v-dialog v-model="status_rut">
      <v-alert border="right" colored-border type="warning" elevation="2">
        <span>
          Error!<br /><br />Rut ingresado inválido o no registrado en base de datos, por favor contáctese con su
          jefatura.</span
        >
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import botasService from "../services/botasService";
export default {
  name: "Home",

  data() {
    return {
      e1: 1,
      rut: "",
      ficha: [],
      nombre: "",
      qrscan: false,
      mov: undefined,
      repcomment: undefined,
      bota: undefined,
      talla: undefined,
      n_talla: false,
      fono: undefined,
      bodega: false,
      jefe: true,
      tostep2: true,
      status_rut: false,
    };
  },
  computed: {
    show_switch: function() {
      if (this.mov != "new") {
        return true;
      } else if (this.talla < 1) {
        return true;
      } else {
        return false;
      }
    },
    edit_talla: function() {
      if (this.mov == "new") {
        if (this.n_talla) {
          return false;
        }
        return true;
      } else {
        if (this.n_talla) {
          return false;
        }
        return true;
      }
    },
    user: function() {
      let user = JSON.parse(sessionStorage.getItem("user"));
      return user.nombre;
    },
    tostep3: function() {
      if (this.mov) {
        if (this.mov == "replace") {
          if (!this.repcomment) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    tostep4: function() {
      if (this.bota || this.fono) {
        if (this.bota) {
          if (!this.talla) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    readqr() {
      this.qrscan = true;
    },
    qr_reader(scanned) {
      // console.log(scanned)
      let figual = scanned.search("N=");
      let fand = scanned.search("&");
      this.rut = scanned.slice(figual + 2, fand);
      this.qrscan = false;
      this.getName();
    },
    getTalla() {
      let data = {};
      data["rut"] = this.rut.replace(/\./g, "").toUpperCase();
      botasService.getTallas(data).then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.talla = response.data.talla;
        } else {
          alert("Ha ocurrido un error y no sa ha podido obtener la talla, revise su conexion a internet");
        }
      });
    },
    async getName() {
      let rut_ = this.rut.replace(/\./g, "").toUpperCase();
      let namecol;
      console.log(rut_);
      await fetch("https://apimedidores.apidev.info/ariztia/getuserbyrut?rut=" + rut_, {
        // mode: "no-cors",
        method: "GET",
      })
        .then((res) => res.json())
        .then(function(response) {
          console.log("response :>> ", response);
          namecol = response.body;
        })
        .catch((e) => {
          console.log(e);
          this.status_rut = true;
        });
      console.log(namecol);
      if (namecol) {
        this.ficha = namecol;
        this.nombre = namecol.nombre;
        this.tostep2 = false;
      } else {
        this.status_rut = true;
        this.nombre = "";
        this.tostep2 = true;
      }
    },
    async regEntrega() {
      let data = {};
      data["NOMBRE_COMP"] = this.ficha.nombre;
      data["NOMBRE_CORT"] = this.ficha.nombre;
      data["RUT"] = this.ficha.rut;
      data["CECO"] = this.ficha.ceco;
      data["NCECO"] = this.ficha.nom_ceco;
      data["T_MOV"] = this.mov;
      data["COMMENT"] = this.repcomment;
      data["BOTA"] = this.bota;
      data["TALLA"] = this.talla;
      data["FONO"] = this.fono;
      data["USER"] = this.user;
      data["MAIL_BOD"] = this.bodega;
      data["MAIL_JEF"] = this.jefe;
      botasService.newEntrega(data).then((response) => {
        console.log(response);
      });
    },
    rstform() {
      this.rut = "";
      this.nombre = "";
      this.qrscan = false;
      this.mov = "";
      this.repcomment = undefined;
      this.bota = undefined;
      this.talla = 0;
      this.fono = undefined;
      this.bodega = false;
      this.jefe = true;
    },
  },
  mounted() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (!user) {
      this.$router.push("/login");
    }
  },
};
</script>
